import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';

import { EventLabels, EventDictionary } from 'helpers/dictionaries';
import {
  managerRegistrationApprove,
  managerRegistrationDeny,
} from 'store/actions/accounts/manager';

import ManagerActionButtons from './ManagerActionButtons';
import Header from './components/Header';

const { EVENT_TYPES } = EventLabels;
const { REG_STATUS } = EventDictionary;

const ManagerActionItemDetail = ({ actionItem, ...props }) => {
  const registrationClasses = classNames('label', {
    'label-info': actionItem.status === REG_STATUS.PENDING,
    'label-warning': actionItem.status === REG_STATUS.APPROVED_PENDING_REVIEW,
    'label-danger': actionItem.status === REG_STATUS.DENIED,
    'label-success': actionItem.status === REG_STATUS.APPROVED,
    'label-default': actionItem.status === REG_STATUS.WITHDRAW,
  });
  const registrationLabel = classNames('Registration', {
    Pending: actionItem.status === REG_STATUS.PENDING,
    'Manager Approved': actionItem.status === REG_STATUS.APPROVED_PENDING_REVIEW,
    Denied: actionItem.status === REG_STATUS.DENIED,
    Approved: actionItem.status === REG_STATUS.APPROVED,
    Withdrawn: actionItem.status === REG_STATUS.WITHDRAW,
  });
  const times =
    actionItem.times && actionItem.times.length > 0
      ? actionItem.times
      : [['No schedule at this time', []]];
  const registrationEnds = moment(actionItem.registration_end);
  const eventStarts = moment(actionItem.first);
  const eventEnds = moment(actionItem.last);
  const lastModified = moment(actionItem.modified);

  return (
    <React.Fragment>
      <Header
        title={actionItem.name}
        className="white-soft-box2--connected-below"
        avatar={actionItem.photo}
      />
      <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
        <div>
          <div style={{ display: 'flex' }}>
            <div className={`event-block--${actionItem.strand_type}`}>
              <div className="event-block__strand-icon" />
            </div>
            <h3 style={{ marginTop: 0 }}>
              {actionItem.title}
              <div className="Grid--index Grid--meta">{EVENT_TYPES[actionItem.type]}</div>
            </h3>
            <div style={{ flex: 1, textAlign: 'right' }}>
              <ManagerActionButtons
                actionItem={actionItem}
                managerRegistrationApprove={props.managerRegistrationApprove}
                managerRegistrationDeny={props.managerRegistrationDeny}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 mt1">
              <div className="pull-left">
                <strong className="info-title">Status</strong>
                <span className={registrationClasses}>{registrationLabel}</span>
                <div>
                  <strong>Last Modified:</strong> {lastModified.format('ddd, MMMM Do YYYY, h:mm a')}
                </div>
              </div>
              <div className="pull-right">
                <strong className="info-title">Important Dates</strong>
                <div>
                  <div>
                    <strong>Registration Ends:</strong>{' '}
                    {registrationEnds.format('ddd, MMMM Do YYYY, h:mm a')}
                  </div>
                  <div>
                    <strong>Event Starts:</strong> {eventStarts.format('ddd, MMMM Do YYYY')}
                  </div>
                  <div>
                    <strong>Event Ends:</strong> {eventEnds.format('ddd, MMMM Do YYYY')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <strong className="info-title mt1">Reason</strong>
          <div className="event-block__line">{actionItem.reason || '(not provided)'}</div>
          <div className="row mt1">
            {times.map((time, index) => (
              <div className="info-description col-xs-12 col-md-6 col-lg-4" key={index}>
                <strong className="info-title">
                  Meeting Schedule
                  {times.length > 1 ? ` (${index + 1})` : null}
                </strong>
                <div className="event-block__line">
                  <span className="event-block__line-icon fa fa-clock-o fa-fw" />
                  {time[0]}
                </div>
                <div className="event-block__line">
                  <span className="event-block__line-icon fa fa-map-marker fa-fw" />
                  {time[2] ? time[2] : 'No Location Assigned'}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ManagerActionItemDetail.title = function(state, params) {
  const actionItem = state.accountsManager.currentActionItem;
  return actionItem.title;
};

ManagerActionItemDetail.propTypes = {
  actionItem: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  managerRegistrationApprove: PropTypes.func.isRequired,
  managerRegistrationDeny: PropTypes.func.isRequired,
  withinEmployee: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    actionItem: state.accountsManager.currentActionItem || {},
  };
};

export default connect(
  mapStateToProps,
  {
    managerRegistrationApprove,
    managerRegistrationDeny,
  }
)(withRouter(ManagerActionItemDetail));
