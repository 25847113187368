import React from 'react';
import classNames from 'classnames';

import { EventLabels, EventDictionary } from 'helpers/dictionaries';
import ActionButton from './ActionButton';
import ManagerActionButtons from '../ManagerActionButtons';

const { EVENT_TYPES } = EventLabels;
const { REG_STATUS } = EventDictionary;

// This isn't rendered as a traditional React component,
// so prop types don't actually get validated at runtime.
/* eslint-disable react/prop-types */
const CellRenderer = ({
  data,
  key,
  style,
  handleInfoClick,
  handleApproveRegistrationClick,
  handleDenyRegistrationClick,
}) => {
  const registrationClasses = classNames('label', {
    'label-info': data.status === REG_STATUS.PENDING,
    'label-warning': data.status === REG_STATUS.APPROVED_PENDING_REVIEW,
    'label-danger': data.status === REG_STATUS.DENIED,
    'label-success': data.status === REG_STATUS.APPROVED,
    'label-default': data.status === REG_STATUS.WITHDRAW,
  });
  const registrationLabel = classNames('Registration', {
    Pending: data.status === REG_STATUS.PENDING,
    'Manager Approved': data.status === REG_STATUS.APPROVED_PENDING_REVIEW,
    Denied: data.status === REG_STATUS.DENIED,
    Approved: data.status === REG_STATUS.APPROVED,
    Withdrawn: data.status === REG_STATUS.WITHDRAW,
  });
  return (
    <div key={key} style={style} className="Grid--row">
      <div className="Grid--letter">
        <img src={data.photo} alt="Employee photo" width="32" height="40" />
      </div>
      <div className="Grid--employee">
        <div className="Grid--name">{data.name}</div>
        <div className="Grid--index">
          <span className={registrationClasses}>{registrationLabel}</span>
        </div>
      </div>
      <div className={`Grid--strand event-block--${data.strand_type}`}>
        <div className="event-block__strand-icon" />
      </div>
      <div className="Grid--title">
        <div className="Grid--name">{data.title}</div>
        <div className="Grid--index Grid--meta">{EVENT_TYPES[data.type]}</div>
      </div>
      <div className="Grid--title">
        <div
          className="Grid--times"
          style={{ height: '50px', overflow: 'scroll', minWidth: '150px' }}
        >
          {data.times.length > 0 ? (
            data.times.map((item, i) => <p key={i}>{item[0]}</p>)
          ) : (
            <p>No dates</p>
          )}
        </div>
      </div>
      <div className="Grid--action">
        <ManagerActionButtons
          actionItem={data}
          managerRegistrationApprove={handleApproveRegistrationClick}
          managerRegistrationDeny={handleDenyRegistrationClick}
        />{' '}
        <ActionButton
          pk={data.actionItemPk}
          onClick={handleInfoClick}
          classes="btn btn-outline-info"
          icon="fa-info"
        />
      </div>
    </div>
  );
};
/* eslint-enable react/prop-types */

export default CellRenderer;
