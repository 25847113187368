import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AutoSizer, Grid } from 'react-virtualized';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap-modal';
import moment from 'moment';
import ReactRouterPropTypes from 'react-router-prop-types';

import {
  changeManagerAcademicYear,
  managerRegistrationApprove,
  managerRegistrationDeny,
  showPreviouslyActionedItems,
} from 'store/actions/accounts/manager';
import { AcademicYearSelect } from 'components/shared';
import Header from './components/Header';
import { employeePropTypes } from './proptypes';
import CellRenderer from './components/CellRenderer';

const filterActionItemsByAcademicYear = (actionItems, academicyear) => {
  const [fallYear, springYear] = academicyear.split('-');
  const fallAcademicYear = moment(`${fallYear}-08-01`);
  const springAcademicYear = moment(`${springYear}-07-31`);
  const filteredActionItems = actionItems.filter((item) => {
    const date = moment(item.first);
    if (date.isSameOrAfter(fallAcademicYear) && date.isSameOrBefore(springAcademicYear)) {
      return item;
    }
  });
  return filteredActionItems;
};

class ManagerActionItemsList extends React.PureComponent {
  static propTypes = {
    employee: employeePropTypes,
    scopedToEmployee: PropTypes.bool.isRequired,
    actionItems: PropTypes.array.isRequired,
    academicyear: PropTypes.string.isRequired,
    changeManagerAcademicYear: PropTypes.func.isRequired,
    managerRegistrationApprove: PropTypes.func.isRequired,
    managerRegistrationDeny: PropTypes.func.isRequired,
    showPreviouslyActionedItems: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    history: ReactRouterPropTypes.history.isRequired,
  };

  static title(state, params) {
    const { accountsManager: manager } = state;
    const employee = manager.employees.find(
      (item) => item.profile.slug === manager.currentEmployee
    );
    return employee.name;
  }

  state = {
    modalOpen: false,
    registrationPk: undefined,
    reason: '',
    actionItems:
      filterActionItemsByAcademicYear(this.props.actionItems, this.props.academicyear) || [],
  };

  onInputChange = (e) => this.setState({ [e.target.name]: e.target.value });

  closeModal = () => this.setState({ modalOpen: false });

  closeAndSubmit = () => {
    this.setState({ modalOpen: false });
    this.props.managerRegistrationDeny(this.state.registrationPk, { message: this.state.reason });
  };

  handleApproveRegistrationClick = (registrationPk) => {
    this.props.managerRegistrationApprove(registrationPk);
  };

  handleDenyRegistrationClick = (registrationPk) => {
    this.setState({ modalOpen: true, registrationPk });
  };

  handleInfoClick = (actionItemPk) => {
    const actionItem = this.state.actionItems.find((item) => item.actionItemPk === actionItemPk);
    this.props.history.push(
      `/events/manager-dashboard/${actionItem.slug}/action-items/${actionItemPk}`
    );
  };

  handleAcademicYearChange = (academicyear) => this.props.changeManagerAcademicYear(academicyear);

  _noContentRenderer = () => (
    <div className="Grid--no-rows">
      <div className="page-empty page-empty--no-space">
        <div className="page-empty__icon">
          <span className="fa fa-check-square-o" />
        </div>
        <div className="page-empty__title">All clear!</div>
        <div className="page-empty__subtitle">No action items</div>
      </div>
    </div>
  );

  _cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    return CellRenderer({
      key,
      style,
      data: this.state.actionItems[rowIndex],
      handleInfoClick: this.handleInfoClick,
      handleApproveRegistrationClick: this.handleApproveRegistrationClick,
      handleDenyRegistrationClick: this.handleDenyRegistrationClick,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (
      this.props.actionItems !== nextProps.actionItems ||
      this.props.academicyear !== nextProps.academicyear
    ) {
      this.setState({
        actionItems: filterActionItemsByAcademicYear(
          nextProps.actionItems,
          nextProps.academicyear || this.props.academicyear
        ),
      });
    }
  }

  render() {
    const helpBlockStyles = { minHeight: '22px', marginTop: '0', marginBottom: '0' };

    let title = 'Manager Dashboard';
    let avatar = null;
    let gridHeight = 300;

    if (this.props.scopedToEmployee) {
      title = this.props.employee.name;
      avatar = this.props.employee.profile.avatar;
    }

    return (
      <div>
        <Header title={title} className="white-soft-box2--connected-below" avatar={avatar} />
        <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={this.props.checked}
                    onChange={(e) => this.props.showPreviouslyActionedItems(e.target.checked)}
                  />{' '}
                  View Previously Actioned Items
                </label>
              </div>
            </div>
            <div className="col-xs-12 col-md-8">
              <div className="pull-right">
                <AcademicYearSelect
                  value={this.props.academicyear}
                  onChange={this.handleAcademicYearChange}
                  style={{ width: 200 }}
                />
              </div>
            </div>
            <div className="col-xs-12">
              <div className="help-block" style={helpBlockStyles}>
                {this.props.checked ? (
                  <div>
                    <i className="fa fa-info-circle icon-space-r" />
                    Displaying items you have already actioned. You may no longer be able to change
                    these items.
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Grid
                className="Grid"
                height={gridHeight}
                overscanRowCount={10}
                noContentRenderer={this._noContentRenderer}
                rowCount={this.state.actionItems.length}
                rowHeight={56}
                cellRenderer={this._cellRenderer}
                columnCount={1}
                columnWidth={Math.max(width - 15, 600)}
                width={width}
                actionItems={this.state.actionItems}
              />
            )}
          </AutoSizer>
          <Modal
            show={this.state.modalOpen}
            onHide={this.closeModal}
            aria-labelledby="ModalHeader"
            modalPrefix="bootstrap-modal modal"
          >
            <Modal.Header closeButton>
              <Modal.Title id="ModalHeader">Registration Denial Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label htmlFor="reason" className="label-control">
                  Personal Message to Employee
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  placeholder="Personal Message..."
                  onChange={this.onInputChange}
                  className="textarea form-control"
                />
                <p className="help-block">Optional, you may leave this blank if you prefer.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-info" onClick={this.closeAndSubmit}>
                Submit
              </button>
              <Modal.Dismiss className="btn btn-default">Cancel</Modal.Dismiss>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { accountsManager: manager } = state;
  const scopedToEmployee = !!manager.currentEmployee;
  const actionItems = manager.showPreviouslyActionedItems
    ? scopedToEmployee
      ? manager.actionedItems.filter((item) => item.slug === manager.currentEmployee)
      : manager.actionedItems
    : scopedToEmployee
    ? manager.actionItems.filter((item) => item.slug === manager.currentEmployee)
    : manager.actionItems;
  return {
    actionItems,
    checked: manager.showPreviouslyActionedItems,
    academicyear: manager.academicyear,
    employee: manager.employees.find((item) => item.profile.slug === manager.currentEmployee),
    scopedToEmployee,
  };
};

export default connect(
  mapStateToProps,
  {
    changeManagerAcademicYear,
    managerRegistrationApprove,
    managerRegistrationDeny,
    showPreviouslyActionedItems,
  }
)(withRouter(ManagerActionItemsList));
