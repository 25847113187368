import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PageHeader } from 'components';
import HelpModal from './HelpModal';

class Header extends React.Component {
  static propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    avatar: PropTypes.string,
    hideHelp: PropTypes.bool,
  };

  state = {
    modalOpen: false,
  };

  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const className = classNames(
      'col-xs-12 white-soft-box2 white-soft-box2--no-animate',
      this.props.className
    );
    return (
      <React.Fragment>
        <div className={className}>
          <PageHeader.WithAvatar src={this.props.avatar} alt="Avatar">
            <PageHeader.Basic
              title={this.props.title}
              actions={
                this.props.hideHelp ? (
                  ' '
                ) : (
                  <button
                    type="button"
                    className="btn btn-info pull-right"
                    onClick={this.openModal}
                  >
                    Help
                  </button>
                )
              }
            />
          </PageHeader.WithAvatar>
          <div className="mt1" />
          {this.props.children}
        </div>
        {!this.props.hideHelp && <HelpModal show={this.state.modalOpen} onHide={this.closeModal} />}
      </React.Fragment>
    );
  }
}

export default Header;
