import React from 'react';
import PropTypes from 'prop-types';

import EmployeeCard from './EmployeeCard';
import { employeePropTypes } from './proptypes';
import Header from './components/Header';

import { connect } from 'react-redux';

import { Alert, Loading } from 'components';
import { searchEmployees } from 'store/actions/accounts/manager';

class ManagerEmployees extends React.Component {
  static propTypes = {
    searchValue: PropTypes.string,
    employees: PropTypes.arrayOf(employeePropTypes),
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    searchEmployees: PropTypes.func.isRequired,
  };

  handleEmployeeSearchChange = (e) => {
    this.props.searchEmployees(e.target.value);
  };

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }
    if (this.props.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Could not retrieve employee data.
        </Alert>
      );
    }

    return (
      <div>
        <Header title="Employees" hideHelp>
          <div>
            <label className="sr-only control-label" htmlFor="search">
              Search Employees
            </label>
            <div className="input-group">
              <span className="input-group-addon">
                <span className="fa fa-search" />
              </span>
              <input
                type="text"
                className="form-control"
                id="search"
                placeholder="Search by employee name or email..."
                onChange={this.handleEmployeeSearchChange}
                value={this.props.searchValue}
              />
            </div>
          </div>
        </Header>
        <div className="col-xs-12">
          <div className="row">
            {this.props.employees.map((employee) => (
              <div className="col-sm-6 col-lg-4" key={employee.pk}>
                <EmployeeCard employee={employee} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    employees: state.accountsManager.filteredEmployees,
    isLoading: state.accountsManager.isLoading,
    hasErrored: state.accountsManager.hasErrored,
    searchValue: state.accountsManager.searchValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchEmployees: (query) => dispatch(searchEmployees(query)),
  };
};

export const ManagerEmployeesUnwrapped = ManagerEmployees;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerEmployees);
