import React from 'react';
import { PinraEvents } from '../../api';
import { Alert, Loading } from 'components';
import { failedAssertion } from 'helpers/reporting';
// import ManagerPinraForm from './ManagerPinraForm';
import PinraCard from './PinraCard';

export default class ManagerPinra extends React.Component {
  state = {
    listLoading: true,
    listError: false,
    pinras: [],
  };

  async componentDidMount() {
    try {
      PinraEvents.managerGetPinras('False').then((res) => {
        // PinraEvents.list().then((res) => {
        this.setState({ pinras: res, listLoading: false, listError: false });
      });
    } catch (err) {
      failedAssertion(err);
      this.setState({ listError: true, listLoading: false });
    }
  }

  render() {
    if (this.state.listError) {
      return (
        <div className="col-xs-12">
          <Alert type="danger" icon="fa-exclamation-triangle">
            Something went wrong. Our team has been notified, please try again or check back later.
          </Alert>
        </div>
      );
    }
    if (this.state.listLoading) {
      return (
        <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
          <h1>PINRA's</h1>
          <hr />
          <div className="row">
            <div className="col-xs-12">
              <Loading />
            </div>
          </div>
        </div>
      );
    }
    if (!this.state.pinras || !this.state.pinras.length) {
      return (
        <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
          <h1>PINRA's</h1>
          <hr />
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <p className="text-size">There are no active PINRA's.</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
        <h1>PINRA's</h1>
        <hr />
        <div className="row">
          {this.state.pinras.map((pinra) => (
            <div className="col-sm-6 col-lg-4" key={pinra.id}>
              <PinraCard pinra={pinra} />
            </div>
          ))}
        </div>
      </div>
      // <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
      // <h1>PINRA's</h1>
      // <hr />
      // {/* <div className="row" style={{ borderBottom: '1px solid black' }}>
      //   <div className="text-center col-xs-12 col-md-1 bold">Name</div>
      //   <div className="text-center col-xs-12 col-md-1 bold">RID#</div>
      //   <div className="text-center col-xs-12 col-md-2 bold">Activity code</div>
      //   <div className="text-center col-xs-12 col-md-1 bold">Event Name</div>
      //   <div className="text-center col-xs-12 col-md-1 bold">Content Area</div>
      //   <div className="text-center col-xs-12 col-md-2 bold">Event Date</div>
      //   <div className="text-center col-xs-12 col-md-1 bold">Event theme</div>
      //   <div className="text-center col-xs-12 col-md-1 bold">CEU's</div>
      //   <div className="text-center col-xs-12 col-md-2 bold">Status</div>
      // </div> */}
      //   {/* {this.state.pinras.map((pinra) => ( */}
      //     {/* <div key={pinra.id}> */}
      //       // <ManagerPinraForm pinra={pinra} />
      //       {/* <PinraCard pinra={pinra} /> */}
      //     // </div>
      //   // ))}
      // // </div>
    );
  }
}
