import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Profile, PinraEvents } from '../../api';
import { object, number } from 'yup';
import { Formik } from 'formik';
import Modal from 'react-bootstrap-modal';
import { failedAssertion } from 'helpers/reporting';

const activityCodeSchema = object().shape({
  activity_code_number: number()
    .positive()
    .integer(),
});

const ceusSchema = object().shape({
  activity_code_number: number().positive(),
});

export default class PinraCard extends React.Component {
  static propTypes = {
    pinra: PropTypes.object,
    activity_code_number: PropTypes.string,
    ceus_to_be_awarded: PropTypes.string,
  };
  state = {
    activityCode: this.props.pinra.activity_code_number,
    ceusToBeAwarded: this.props.pinra.ceus_to_be_awarded,
    status: this.props.pinra.status,
    participant: {},
    showWithdraw: false,
    showDrop: false,
    reason: '',
    contentArea: '',
  };

  async componentDidMount() {
    try {
      Profile.detail(this.props.pinra.slug).then((res) => {
        this.setState({ participant: res });
      });
    } catch (err) {
      failedAssertion(err);
      this.setState({ listError: true, listLoading: false });
    }
  }

  showWithdrawModal = () => this.setState({ showWithdraw: true });
  hideWithdrawModal = () => this.setState({ showWithdraw: false });

  showDropModal = () => this.setState({ showDrop: true });
  hideDropModal = () => this.setState({ showDrop: false });

  updateActivityCode = async (event) => {
    event.preventDefault();
    try {
      await PinraEvents.updateActivityCode({
        id: this.props.pinra.id,
        activity_code_number: this.state.activityCode,
      });
      window.show_stack_topleft('Success', 'Activity Code has been updated!', 'success');
    } catch (err) {
      failedAssertion(err);
    }
  };

  updateCeus = async (event) => {
    event.preventDefault();
    try {
      await PinraEvents.updateCeus({
        id: this.props.pinra.id,
        ceus_to_be_awarded: this.state.ceusToBeAwarded,
      });
      window.show_stack_topleft('Success', 'Ceus to be awarded has been updated!', 'success');
    } catch (err) {
      failedAssertion(err);
    }
  };

  updateStatus(event, _status) {
    event.preventDefault();
    this.hideWithdrawModal();
    this.hideDropModal();
    this.setState({ status: _status }, () => {
      try {
        PinraEvents.updateStatus({
          id: this.props.pinra.id,
          status: this.state.status,
          reason: this.state.reason,
        });
        window.show_stack_topleft('Success', 'Pinra Status has been updated!', 'success');
      } catch (err) {
        failedAssertion(err);
      }
    });
  }

  updateContentArea = async (event, _contentArea) => {
    event.preventDefault();
    this.setState({ contentArea: _contentArea }, () => {
      try {
        PinraEvents.updateContentArea({
          id: this.props.pinra.id,
          content_area: this.state.contentArea,
        });
        window.show_stack_topleft('Success', 'Pinra content area has been updated!', 'success');
      } catch (err) {
        failedAssertion(err);
      }
    });
  };

  handleChange = (event) => this.setState({ [event.target.name]: event.target.value });

  render() {
    return (
      <div className="employee-card employee-card--hover" style={{ paddingBottom: 15 }}>
        <div className="employee-card--header" />
        <div className="employee-card--avatar">
          {this.state.participant.avatar ? (
            <img src={this.state.participant.avatar} alt="Employee Photo" />
          ) : (
            <img
              src="https://secure.gravatar.com/avatar/fb4fac536a2e7608fb5834af8852bdc6.jpg?s=145&d=mm&r=g"
              alt="Employee Photo"
            />
          )}
        </div>
        <div className="employee-card--info">
          <div className="employee-card--title">
            <a
              href={`/pinra/${this.props.pinra.id}/rid-pinra-form/export`}
              target="_blank"
              title="Export PDF"
            >
              {this.props.pinra.participant_name}
            </a>
          </div>
          <div className="employee-card--desc-container">
            <div className="employee-card--desc">RID #: {this.props.pinra.rid_member_number}</div>
            <div className="employee-card--desc">
              Activity/Event: {this.props.pinra.activity_name}
            </div>
            <div className="employee-card--desc">
              Activity Theme: {this.props.pinra.activity_theme}
            </div>
            <div className="employee-card--desc">
              <div className="row" style={{ paddingLeft: 10 }}>
                <form onSubmit={this.updateActivityCode} className="form">
                  <label style={{ fontWeight: 400 }}>Content Area: </label>
                  <select
                    style={{ borderRadius: 5, marginLeft: 10 }}
                    defaultValue={this.props.pinra.content_area}
                    onChange={(e) => this.updateContentArea(e, e.target.value)}
                  >
                    <option value="p">Professional Studies</option>
                    <option value="g">General Studies</option>
                  </select>
                </form>
              </div>
            </div>
            <div>
              <Formik
                initialValues={{
                  ceus_to_be_awarded: '',
                }}
                validationSchema={ceusSchema}
              >
                <form onSubmit={this.updateCeus} className="form">
                  <div className="employee-card--desc">CEU's to be awarded:</div>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="ceusToBeAwarded"
                        value={this.state.ceusToBeAwarded}
                        onChange={this.handleChange}
                        id={`id_ceus_${this.props.pinra.id}`}
                        className="form-control"
                        style={{ width: '80px' }}
                      />
                    </div>
                    <div className="col-md-6">
                      <button className="btn btn-secondary">Update CEU's</button>
                    </div>
                  </div>
                </form>
              </Formik>
            </div>
            <div style={{ paddingBottom: 25 }}>
              <Formik
                initialValues={{
                  activity_code_number: '',
                }}
                validationSchema={activityCodeSchema}
              >
                <form onSubmit={this.updateActivityCode} className="form">
                  <div className="employee-card--desc">Activity Code:</div>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        name="activityCode"
                        value={this.state.activityCode}
                        onChange={this.handleChange}
                        id={`id_${this.props.pinra.id}`}
                        className="form-control"
                        style={{ width: 150 }}
                      />
                    </div>
                    <div className="col-md-6">
                      <button className="btn btn-secondary">Update Activity Code</button>
                    </div>
                  </div>
                </form>
              </Formik>
            </div>
            {(() => {
              if (this.state.status === '0')
                return (
                  <div className="text-center col-xs-12 col-md-12">
                    CEU Support denied. This event will disappear on refresh
                  </div>
                );
              if (this.state.status === '1')
                return (
                  <div className="text-center col-xs-12 col-md-12">
                    Waiting on sponsor approval to attend event
                    <br />
                    <div className="row">
                      <button
                        className=" btn btn-primary"
                        style={{ marginLeft: '5px' }}
                        onClick={(e) => this.updateStatus(e, '2')}
                      >
                        Approve
                      </button>
                      <button
                        className=" btn btn-danger"
                        style={{ marginLeft: '5px' }}
                        // onClick={(e) => this.updateStatus(e, '0')}
                        onClick={this.showDropModal}
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                );
              if (this.state.status === '2')
                return (
                  <div className="text-center col-xs-12 col-md-12">
                    <div>Waiting for person to attend their event</div>
                    <div style={{ marginTop: 5 }}>
                      <button
                        title="Dropping this event will remove it from your transcript"
                        className=" btn btn-danger"
                        onClick={this.showWithdrawModal}
                      >
                        Withdraw PINRA
                      </button>
                    </div>
                  </div>
                );
              if (this.state.status === '3')
                return (
                  <div className="text-center col-xs-12 col-md-12">
                    <div>Waiting for person to upload documents</div>
                    <div style={{ marginTop: 5 }}>
                      <button
                        title="Withdrawing from this event will give you an Incomplete on your transcript"
                        className=" btn btn-danger"
                        onClick={this.showWithdrawModal}
                      >
                        Withdraw PINRA
                      </button>
                    </div>
                  </div>
                );
              if (this.state.status === '4')
                return (
                  <div className="text-center col-xs-12 col-md-12">
                    Waiting for sponsor final approval
                    <br />
                    <div className="row">
                      <button
                        className=" btn btn-primary"
                        style={{ marginLeft: '5px' }}
                        onClick={(e) => this.updateStatus(e, '5')}
                      >
                        Approved
                      </button>
                      <button
                        className=" btn btn-danger"
                        style={{ marginLeft: '5px' }}
                        onClick={this.showWithdrawModal}
                      >
                        Denied
                      </button>
                    </div>
                  </div>
                );
              if (this.state.status === '5')
                return (
                  <div className="text-center col-xs-12 col-md-12">This Pinra is complete.</div>
                );
              if (this.state.status === '6')
                return (
                  <div className="text-center col-xs-12 col-md-12">
                    This PINRA has been withdrawn and will disappear on the next page reload.
                  </div>
                );
            })()}
          </div>
        </div>
        <div className="employee-card--bottom">
          <Link to={`/events/manager-dashboard/${this.props.pinra.slug}/action-items`}>
            {/* <button type="button" className="btn btn-default">
              Action Items
              <span
                className={classNames('badge icon-space-l', {
                  'employee-card--badge-pending': this.props.employee.action_items.length > 0,
                  'employee-card--badge-default': this.props.employee.action_items.length < 1,
                })}
              >
                {this.props.employee.action_items.length}
              </span>
            </button> */}
          </Link>
          {/* <Link to={this.props.employee.url} className="btn btn-default icon-space-l">
            Profile
          </Link> */}
        </div>
        {/* model for dropping */}
        <Modal
          show={this.state.showDrop}
          onHide={this.hideDropModal}
          modalPrefix="bootstrap-modal modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you wish to drop this PINRA?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Dropping will remove this PINRA event from the system.</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-warning" onClick={this.hideDropModal}>
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={(e) => this.updateStatus(e, '0', this.props.pinra.id)}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>

        {/* model for withdrawing */}
        <Modal
          show={this.state.showWithdraw}
          onHide={this.hideWithdrawModal}
          modalPrefix="bootstrap-modal modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you wish to withdraw from this PINRA?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Support for this event has been withdrawn. Please indicate a reason.
            <form>
              <label htmlFor="reason">Reason: </label>
              <textarea
                name="reason"
                cols="40"
                rows="10"
                placeholder="Reason for withdrawing support"
                className="form-control"
                id="id_body"
                value={this.state.reason}
                onChange={this.handleChange}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-warning" onClick={this.hideWithdrawModal}>
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={(e) => this.updateStatus(e, '6', this.props.pinra.id)}
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
