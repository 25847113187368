import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap-modal';

import ActionButton from './ActionButton';

const ignoreOnClick = (e) => null;

const HelpModal = ({ show, onHide }) => (
  <Modal
    show={show}
    onHide={onHide}
    aria-labelledby="ModalHeader"
    modalPrefix="bootstrap-modal modal"
  >
    <Modal.Header closeButton>
      <Modal.Title id="ModalHeader">Help</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Button</th>
              <th>Action</th>
              <th>Notification</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ActionButton
                  classes="btn btn-outline-info"
                  icon="fa-info"
                  pk={-1}
                  onClick={ignoreOnClick}
                />
              </td>
              <td>
                Takes you to a detailed view of the action item. You can easily move back to the
                same place in the dashboard.
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <ActionButton
                  classes="btn btn-outline-success"
                  icon="fa-check"
                  pk={-1}
                  onClick={ignoreOnClick}
                />
              </td>
              <td>
                Approves this registration request. The item will be considered actioned and no
                further emails regarding it will be sent to you.
              </td>
              <td>
                None, employees who log into the site can see the updated status through their
                dashboard if they wish.
              </td>
            </tr>
            <tr>
              <td>
                <ActionButton
                  classes="btn btn-outline-danger"
                  icon="fa-times"
                  pk={-1}
                  onClick={ignoreOnClick}
                />
              </td>
              <td>
                Denies this registration request. The item will be considered actioned and no
                further emails regarding it will be sent to you.
              </td>
              <td>
                No immediate notification. The morning following the denial the employee will be
                emailed if the decision still stands.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Modal.Dismiss className="btn btn-default">Close</Modal.Dismiss>
    </Modal.Footer>
  </Modal>
);
HelpModal.propTypes = {
  show: PropTypes.bool, // leave "isRequired" up to <Modal>
  onHide: PropTypes.func, // leave "isRequired" up to <Modal>
};

export default HelpModal;
