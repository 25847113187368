import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { employeePropTypes } from 'components/manager-dashboard/proptypes';

export default class EmployeeCard extends React.Component {
  static propTypes = {
    employee: employeePropTypes,
  };

  render() {
    return (
      <div className="employee-card employee-card--hover">
        <div className="employee-card--header" />
        <div className="employee-card--avatar">
          <img src={this.props.employee.profile.avatar} alt="Employee Photo" />
        </div>
        <div className="employee-card--info">
          <div className="employee-card--title">{this.props.employee.name}</div>
          <div className="employee-card--desc-container">
            {this.props.employee.profile.title ? (
              <div className="employee-card--desc">
                <span className="fa fa-briefcase fa-fw icon-space-l icon-space-r" />
                {this.props.employee.profile.title}
              </div>
            ) : null}
            {this.props.employee.profile.phone ? (
              <div className="employee-card--desc">
                <span className="fa fa-phone fa-fw icon-space-l icon-space-r" />
                {this.props.employee.profile.phone}
              </div>
            ) : null}
            {this.props.employee.profile.office ? (
              <div className="employee-card--desc">
                <span className="fa fa-map-marker fa-fw icon-space-l icon-space-r" />
                {this.props.employee.profile.office}
              </div>
            ) : null}
            {this.props.employee.email ? (
              <div className="employee-card--desc">
                <span className="fa fa-envelope fa-fw icon-space-l icon-space-r" />
                <a href={`mailto:${this.props.employee.email}`}>{this.props.employee.email}</a>
              </div>
            ) : null}
          </div>
        </div>
        <div className="employee-card--bottom">
          <Link to={`/events/manager-dashboard/${this.props.employee.profile.slug}/action-items`}>
            <button type="button" className="btn btn-default">
              Action Items
              <span
                className={classNames('badge icon-space-l', {
                  'employee-card--badge-pending': this.props.employee.action_items.length > 0,
                  'employee-card--badge-default': this.props.employee.action_items.length < 1,
                })}
              >
                {this.props.employee.action_items.length}
              </span>
            </button>
          </Link>
          <Link to={this.props.employee.url} className="btn btn-default icon-space-l">
            Profile
          </Link>
        </div>
      </div>
    );
  }
}
