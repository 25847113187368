import PropTypes from 'prop-types';

export const employeePropTypes = PropTypes.shape({
  pk: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    pk: PropTypes.number.isRequired,
    title: PropTypes.string,
    phone: PropTypes.string,
    office: PropTypes.string,
    avatar: PropTypes.string.isRequired,
    manager: PropTypes.number.isRequired,
  }),
  user_registrations: PropTypes.arrayOf(
    PropTypes.shape({
      pk: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
});
