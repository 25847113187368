import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { EventDictionary } from 'helpers/dictionaries';
import ActionButton from './components/ActionButton';

const { REG_STATUS } = EventDictionary;

const ManagerActionButtons = ({ actionItem, ...props }) => {
  let actionButtons = [];
  const eventEnds = moment(actionItem.last);
  const isPastEvent = moment() > eventEnds;
  // registration action item
  if (actionItem.status === REG_STATUS.PENDING && !isPastEvent) {
    actionButtons.push(
      <ActionButton
        pk={actionItem.actionItemPk}
        onClick={() => props.managerRegistrationApprove(actionItem.actionItemPk)}
        classes={'btn btn-outline-success'}
        icon={'fa-check'}
      />
    );
    actionButtons.push(
      <ActionButton
        pk={actionItem.actionItemPk}
        onClick={() => props.managerRegistrationDeny(actionItem.actionItemPk)}
        classes={'btn btn-outline-danger'}
        icon={'fa-times'}
      />
    );
  } else if (actionItem.status === REG_STATUS.APPROVED_PENDING_REVIEW && !isPastEvent) {
    actionButtons.push(
      <ActionButton
        pk={actionItem.actionItemPk}
        onClick={() => props.managerRegistrationDeny(actionItem.actionItemPk)}
        classes={'btn btn-outline-danger'}
        icon={'fa-times'}
      />
    );
  } else if (actionItem.status === REG_STATUS.DENIED && !isPastEvent) {
    actionButtons.push(
      <ActionButton
        pk={actionItem.actionItemPk}
        onClick={() => props.managerRegistrationApprove(actionItem.actionItemPk)}
        classes={'btn btn-outline-success'}
        icon={'fa-check'}
      />
    );
  }
  return (
    <span>
      {actionButtons.map((button, idx) => (
        <span key={idx}> {button}</span>
      ))}
    </span>
  );
};

ManagerActionButtons.propTypes = {
  actionItem: PropTypes.object.isRequired,
  managerRegistrationApprove: PropTypes.func.isRequired,
  managerRegistrationDeny: PropTypes.func.isRequired,
};

export default ManagerActionButtons;
