import React from 'react';
import PropTypes from 'prop-types';

export default class ActionButton extends React.Component {
  static propTypes = {
    pk: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    classes: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  };

  handleClick = () => {
    this.props.onClick(this.props.pk);
  };

  render() {
    return (
      <button type="button" className={`btn ${this.props.classes}`} onClick={this.handleClick}>
        <i className={`fa ${this.props.icon}`} />
      </button>
    );
  }
}
