import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import {
  requestEmployees,
  getActionItems,
  selectActionItem,
  updateManagerDashboardEmployee,
} from 'store/actions/accounts/manager';
import { Alert, Loading, BreadRoute, NotFound } from 'components';
import ManagerEmployees from './ManagerEmployees';
import ManagerEvents from './ManagerEvents';
import ManagerActionItemsList from './ManagerActionItemsList';
import ManagerActionItemDetail from './ManagerActionItemDetail';
import { requestCurrentUser } from 'store/actions/accounts/user';
import ManagerPinra from './ManagerPinra';
import ManagerCompletedPinras from './ManagerCompletedPinras';

export class ManagerDashboard extends React.Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    hasErrored: PropTypes.bool.isRequired,
    fetchData: PropTypes.func.isRequired,
    fetchUserData: PropTypes.func.isRequired,
    setCurrentEmployee: PropTypes.func.isRequired,
    getActionItems: PropTypes.func.isRequired,
    selectActionItem: PropTypes.func.isRequired,
  };

  state = {
    modalOpen: false,
    currentUser: '',
  };

  componentDidMount() {
    this.props.fetchData();
    this.props.fetchUserData().then((res) => this.setState({ currentUser: res.email }));
  }

  render() {
    if (this.props.isLoading) {
      return <Loading />;
    }
    if (this.props.hasErrored) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Could not retrieve employee data.
        </Alert>
      );
    }
    return (
      <Switch>
        <BreadRoute
          exact
          path="/events/manager-dashboard"
          component={() => {
            this.props.getActionItems();
            this.props.setCurrentEmployee(null);
            return (
              <div>
                <ManagerActionItemsList />
                {(this.state.currentUser === 'dsmdis@rit.edu' ||
                  this.state.currentUser === 'erfdis@rit.edu') && <ManagerPinra />}
                {(this.state.currentUser === 'dsmdis@rit.edu' ||
                  this.state.currentUser === 'erfdis@rit.edu') && <ManagerCompletedPinras />}
                <ManagerEmployees />
              </div>
            );
          }}
        />
        <BreadRoute
          exact
          path="/events/manager-dashboard/events"
          component={ManagerEvents}
          title="Events"
        />
        <BreadRoute
          path="/events/manager-dashboard/:slug/action-items"
          title={ManagerActionItemsList.title}
        >
          <Switch>
            <BreadRoute
              exact
              path="/events/manager-dashboard/:slug/action-items"
              component={({ match }) => {
                this.props.getActionItems();
                this.props.setCurrentEmployee(match.params.slug);
                return <ManagerActionItemsList />;
              }}
            />
            <BreadRoute
              exact
              path="/events/manager-dashboard/:slug/action-items/:itemId"
              component={({ match }) => {
                this.props.getActionItems();
                this.props.setCurrentEmployee(match.params.slug);
                this.props.selectActionItem(match.params.itemId);
                return <ManagerActionItemDetail itemId={match.params.itemId} withinEmployee />;
              }}
              title={ManagerActionItemDetail.title}
            />
            <Route component={NotFound} />
          </Switch>
        </BreadRoute>
        <Route component={NotFound} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.accountsManager.isLoading,
    hasErrored: state.accountsManager.hasErrored,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: () => dispatch(requestEmployees()),
    fetchUserData: () => dispatch(requestCurrentUser()),
    selectActionItem: (itemId) => dispatch(selectActionItem(itemId)),
    getActionItems: () => dispatch(getActionItems()),
    setCurrentEmployee: (slug) => dispatch(updateManagerDashboardEmployee(slug)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerDashboard);
