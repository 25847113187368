import React from 'react';
import { PinraEvents } from '../../api';
import { Alert, Loading } from 'components';
import { failedAssertion } from 'helpers/reporting';

export default class ManagerCompletedPinras extends React.Component {
  state = {
    listLoading: true,
    listError: false,
    pinras: [],
  };

  async componentDidMount() {
    try {
      PinraEvents.managerGetPinras('True').then((res) => {
        this.setState({ pinras: res, listLoading: false, listError: false });
      });
    } catch (err) {
      failedAssertion(err);
      this.setState({ listError: true, listLoading: false });
    }
  }

  toggleItem = (e) => {
    const isActive = e.target.classList.contains('active');
    if (isActive) {
      e.target.classList.remove('active');
      e.target.parentElement.classList.remove('active');
    } else {
      e.target.classList.add('active');
      e.target.parentElement.classList.add('active');
    }
  };

  render() {
    if (this.state.listError) {
      return (
        <div className="col-xs-12">
          <Alert type="danger" icon="fa-exclamation-triangle">
            Something went wrong. Our team has been notified, please try again or check back later.
          </Alert>
        </div>
      );
    }
    if (this.state.listLoading) {
      return (
        <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
          <h1>Compelted PINRA's</h1>
          <hr />
          <div className="row">
            <div className="col-xs-12">
              <Loading />
            </div>
          </div>
        </div>
      );
    }
    if (!this.state.pinras || !this.state.pinras.length) {
      return (
        <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
          <h1>Completed PINRA's</h1>
          <hr />
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <p className="text-size">There are no completed PINRA's.</p>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="col-xs-12 white-soft-box2 white-soft-box2--no-animate white-soft-box2--connected-above">
        <ul className="nav accordion">
          <h1>Completed PINRA's</h1>
          <li className="search-item">
            <a className="search-text" onClick={this.toggleItem}>
              <i className="fa fa-fw fa-angle-right" />
              <i className="fa fa-fw fa-angle-down" />
              Click to see completed PINRA's
            </a>
            <div style={{ marginBottom: 15 }}>
              <div className="row" style={{ borderBottom: '1px solid #e0e0e0' }}>
                <div className="text-center col-xs-12 col-md-1 bold">Name</div>
                <div className="text-center col-xs-12 col-md-1 bold">RID#</div>
                <div className="text-center col-xs-12 col-md-2 bold">Activity code</div>
                <div className="text-center col-xs-12 col-md-2 bold">Event Name</div>
                <div className="text-center col-xs-12 col-md-2 bold">Event Date</div>
                <div className="text-center col-xs-12 col-md-1 bold">Event theme</div>
                <div className="text-center col-xs-12 col-md-1 bold">CEU's</div>
                <div className="text-center col-xs-12 col-md-2 bold">Status</div>
              </div>
              {this.state.pinras.map((pinra) => (
                <div key={pinra.id}>
                  <div
                    className="row"
                    style={{ borderBottom: '1px solid #e0e0e0', paddingTop: 10, paddingBottom: 10 }}
                  >
                    <div className="text-center col-xs-12 col-md-1">
                      <a
                        href={`/pinra/${pinra.id}/rid-pinra-form/export`}
                        target="_blank"
                        title="Export PDF"
                      >
                        {pinra.participant_name}
                      </a>
                    </div>
                    <div className="text-center col-xs-12 col-md-1">{pinra.rid_member_number}</div>
                    <div className="text-center col-xs-12 col-md-2">
                      {pinra.activity_code_number}
                    </div>
                    <div className="text-center col-xs-12 col-md-2">{pinra.activity_name}</div>
                    <div className="text-center col-xs-12 col-md-2">
                      {pinra.date_times_attending}
                    </div>
                    <div className="text-center col-xs-12 col-md-1">{pinra.activity_theme}</div>
                    <div className="text-center col-xs-12 col-md-1">{pinra.ceus_to_be_awarded}</div>
                    {(() => {
                      if (pinra.status === '5')
                        return (
                          <div className="text-center col-xs-12 col-md-2" title="Complete">
                            <span className="fa fa-check" /> Complete
                          </div>
                        );
                      if (pinra.status === '6')
                        return (
                          <div
                            className="text-center col-xs-12 col-md-2"
                            title={pinra.withdraw_reason}
                          >
                            <span className="fa fa-window-close" /> Withdrawn
                          </div>
                        );
                    })()}
                  </div>
                </div>
              ))}
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
